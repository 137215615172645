.menu {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  margin: 20px;
}

.menu-header {
  grid-column: 1 / -1;
  padding-top: 65px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.menu-section {
  background-color: #e1c397;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
}

.to-order{
  float: right;
  margin-left: auto;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.menu-section-description {
  text-align: center;
}

.menu-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.menu-entry-name {
  font-weight: bold;
}

.menu-entry-price {
  font-style: italic;
  color: #f05c04;
}

.menu-entry-description {
  font-size: 0.8em;
  color: #0c0cf3;
  margin-top: 5px;
  margin-left: 8px;
}

.menu-section-title {
  text-align: center;
  color: #0077C0;
  margin-bottom: 5px;
}

.menu-section-addon-price{
  color: #f05c04;
}

.menu-section-addons {
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 5px;
}

.menu-section-addon {
  margin-bottom: 5px;
}

.menu-section-addon-name {
  font-weight: bold;
}

.menu-section-addon-price {
  font-style: italic;
}
