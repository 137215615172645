@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #e5c89e;
}

.cart-button-container {
  position: fixed;
  top: 0;
  left: 10;
  width: 100%;
  z-index: 200; 
  padding-top: 60px;
  margin-top: 30px; 
}


.view-cart-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.view-cart-button:hover {
  background-color: #0069d9;
}

.cart-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cart-item {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: center;
}

.cart-item p {
  margin-bottom: 0.5rem;
}

.cart-item ul {
  margin-bottom: 0.5rem;
}

.remove-item-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-item-button:hover {
  background-color: #0069d9;
}

.clear-cart-button {
  background-color: red;
}

.close-modal-button {
  background-color: #0069d9;
}

.checkout-button {
  background-color: green;
}

.clear-cart-button,
.close-modal-button,
.checkout-button {
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
}

.clear-cart-button:last-child,
.close-modal-button:last-child,
.checkout-button:last-child {
  margin-right: 0;
}

.clear-cart-button:hover,
.close-modal-button:hover,
.checkout-button:hover {
  background-color: #5a6268;
}


.order-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.order-section-title {
  font-size: 2rem;
  color: #0077C0;
  margin-bottom: 0.5rem;
}

.order-menu {
  font-family: 'Roboto', sans-serif;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 30px;
}

.order-section-content {
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;
}

.order-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  border: 1px solid black;
  border-radius: 2%;
  padding: 1rem;
  text-align: center;
}

.order-item h4 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.order-item p {
  margin-bottom: 0.5rem;
}

.order-item .view-item-button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.item-price {
  color: #f05c04;
}

.order-item .view-item-button:hover {
  background-color: #0069d9;
}

.modal-content {
  text-align: center;
  padding: 2rem;
}

.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-content h3 {
  text-align: left;
}

.modal-content p {
  margin-bottom: 1rem;
}

.modal-content select {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
}

.modal-content label {
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
}

.modal-content button {
  background-color: #007bff;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #0069d9;
}

.modal-content .close-modal-button {
  background-color: #6c757d;
  margin-right: 0.5rem;
}

.modal-content .add-cart-button {
  background-color: #007bff;
}

.modal-content .add-cart-button:hover {
  background-color: #0069d9;
}


.checkout label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.checkout input[type="text"],
.checkout input[type="email"],
.checkout input[type="tel"] {
  width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  display: block;
}

.checkout button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.checkout button[type="submit"]:hover {
  background-color: #0069d9;
}

.checkout button {
  background-color: #6c757d;
  color: #fff;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.checkout button:last-child {
  margin-right: 0;
}


.header {
  background-size: cover;
  background-position: center;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-content {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: black;
}

.header-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
}

.header-description {
  font-size: 18px;
  margin-bottom: 24px;
}

.protein-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

.protein-option {
  display: flex;
  align-items: center;
}

.protein-option > * {
  margin-right: 5px;
}

.button-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.button-container button {
  margin-left: 5px;
  margin-right: 5px;
}

.total-price {
  padding-top: 15px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-row-gap: 5px;
}

.price-label {
  grid-column: 1;
}

.price-number {
  grid-column: 2;
  text-align: right;
}

.closed-message {
  padding-top: 20%;
  text-align: center;
  padding-bottom: 10%;
}