.home-header {
  background-color: #e5c89e;
  padding: 50px 0;
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.home-header span {
  color: #0077C0;
}

.home-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.home-desc-text {
  flex: 1;
}

.home-desc h2 {
  color: #0077C0;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home-desc p {
  font-size: 18px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: justify;
}

.home-desc img {
  flex: 1;
  object-fit: cover;
  padding-left: 20px;
  height: 100%;
  width: 100%;
}

.location {
  display: flex;
  padding-top: 30px;
  align-items: center;
}

.location-info {
  margin-right: 20px;
}

.map-container {
  margin-left: auto;
}
