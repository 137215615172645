nav {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0077C0;
  color: #fff;
  font-size: 24px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 202;

 
}

@media (max-width: 768px) {
  /* Adjust the styles for mobile devices */
  nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px; /* Add padding for better spacing */
    font-size: 18px; /* Decrease the font size for mobile devices */
  }
}

nav img {
  height: 50px;
  margin-top: 7px;
  margin-left: -9px;
}

nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

nav li {
  margin: 0;
  height: 80px;
  padding: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s ease;
}


nav a {
  color: #fff;
  text-decoration: none;
}

nav li:hover {
  background-color: black;
}

.footer {
  height: 60px;
  color: #fff;
  bottom: 0;
}

.footer img {
  height: 50px;
  margin-top: -15px;
}

.footer h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.footer a {
  color: #0077C0;
}

.footer .footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .footer-content .footer-section-about {
  flex-basis: 35%;
  margin-bottom: 30px;
}

.footer .footer-content .footer-section-about img {
  height: 80px;
}

.footer .footer-content .footer-section-about h3 {
  margin-bottom: 10px;
  color: #0077C0;
  font-size: 20px;
  font-weight: bold;
}

.footer .footer-content .footer-section-about p {
  color: #666;
}

.footer .footer-content .footer-section-links {
  flex-basis: 20%;
  margin-bottom: 30px;
}

.footer .footer-content .footer-section-links h3 {
  margin-bottom: 10px;
  color: #0077C0;
  font-size: 20px;
  font-weight: bold;
}

.footer .footer-content .footer-section-links ul li {
  margin-bottom: 5px;
  list-style-type: none;
  margin: 0;
  margin-bottom: 15px;
  padding: 0;
}

.footer .footer-content .footer-section-links ul li a {
  color: #666;
  text-decoration: none;
}

.footer .footer-content .footer-section-links ul li a:hover {
  color: #0077C0;
}

.footer .footer-content .footer-section-address {
  flex-basis: 35%;
  margin-bottom: 30px;
}

.footer .footer-content .footer-section-address h3 {
  margin-bottom: 10px;
  color: #0077C0;
  font-size: 20px;
  font-weight: bold;
}

.footer .footer-content .footer-section-address p {
  color: #666;
}

.footer .footer-bottom {
  background-color: #0077C0;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
}

.footer .footer-bottom p {
  margin-bottom: 0;
  color: #fff;
}

.footer .footer-bottom a {
  color: #fff;
}

